@import "src/styles/vars";

$color: $default-accent-color;

$base-size: 40px;

.hourglass {
    display: inline-block;
    position: relative;
}

.hourglass:after {
    content: '';
    display: block;
    border-radius: 50%;
    box-sizing: border-box;
    border-color: $color transparent $color transparent;
    animation: hourglass 1.2s infinite;
}

.xsmall {
    width: $base-size/3;
    height: $base-size/3;
}

.xsmall:after {
    border: $base-size/6 solid;
}

.small {
    width: $base-size/2;
    height: $base-size/2;
}

.small:after {
    border: $base-size/4 solid;
}

.medium {
    width: $base-size;
    height: $base-size;
}

.medium:after {
    border: $base-size/2 solid;
}

.large {
    width: $base-size*2;
    height: $base-size*2;
}

.large:after {
    border: $base-size solid;
}

.accent:after {
    border-color: $color transparent $color transparent;
}

.white:after {
    border-color: white transparent white transparent;
}

.warning:after {
    border-color: $default-warning-color transparent $default-warning-color transparent;
}

@keyframes hourglass {
    0% {
        transform: rotate(0);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    50% {
        transform: rotate(900deg);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    100% {
        transform: rotate(1800deg);
    }
}
