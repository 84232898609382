:root {
    /* @link https://utopia.fyi/type/calculator?c=320,14,1.2,1240,28,1.25,5,2,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l&g=s,l,xl,12 */
    --step--2: clamp(0.61rem, calc(0.43rem + 0.89vw), 1.12rem);
    --step--1: clamp(0.73rem, calc(0.5rem + 1.17vw), 1.4rem);
    --step-0: clamp(0.88rem, calc(0.57rem + 1.52vw), 1.75rem);
    --step-1: clamp(1.05rem, calc(0.65rem + 1.98vw), 2.19rem);
    --step-2: clamp(1.26rem, calc(0.75rem + 2.56vw), 2.73rem);
    --step-3: clamp(1.51rem, calc(0.85rem + 3.32vw), 3.42rem);
    --step-4: clamp(1.81rem, calc(0.96rem + 4.27vw), 4.27rem);
    --step-5: clamp(2.18rem, calc(1.08rem + 5.5vw), 5.34rem);

    /* @link https://utopia.fyi/space/calculator?c=320,14,1.2,1240,28,1.25,5,2,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l&g=s,l,xl,12 */

    --space-3xs: clamp(0.25rem, calc(0.18rem + 0.33vw), 0.44rem);
    --space-2xs: clamp(0.44rem, calc(0.29rem + 0.76vw), 0.88rem);
    --space-xs: clamp(0.69rem, calc(0.47rem + 1.09vw), 1.31rem);
    --space-s: clamp(0.88rem, calc(0.57rem + 1.52vw), 1.75rem);
    --space-m: clamp(1.31rem, calc(0.86rem + 2.28vw), 2.63rem);
    --space-l: clamp(1.75rem, calc(1.14rem + 3.04vw), 3.5rem);
    --space-xl: clamp(2.63rem, calc(1.71rem + 4.57vw), 5.25rem);
    --space-2xl: clamp(3.5rem, calc(2.28rem + 6.09vw), 7rem);
    --space-3xl: clamp(5.25rem, calc(3.42rem + 9.13vw), 10.5rem);

    /* One-up pairs */
    --space-3xs-2xs: clamp(0.25rem, calc(0.03rem + 1.09vw), 0.88rem);
    --space-2xs-xs: clamp(0.44rem, calc(0.13rem + 1.52vw), 1.31rem);
    --space-xs-s: clamp(0.69rem, calc(0.32rem + 1.85vw), 1.75rem);
    --space-s-m: clamp(0.88rem, calc(0.27rem + 3.04vw), 2.63rem);
    --space-m-l: clamp(1.31rem, calc(0.55rem + 3.8vw), 3.5rem);
    --space-l-xl: clamp(1.75rem, calc(0.53rem + 6.09vw), 5.25rem);
    --space-xl-2xl: clamp(2.63rem, calc(1.1rem + 7.61vw), 7rem);
    --space-2xl-3xl: clamp(3.5rem, calc(1.07rem + 12.17vw), 10.5rem);

    /* Custom pairs */
    --space-s-l: clamp(0.88rem, calc(-0.04rem + 4.57vw), 3.5rem);
}

* {
    box-sizing: border-box;
}

body {
    font-family: 'Montserrat', 'Source Sans Pro', Helvetica, 'Segoe UI',
        'Nimbus Sans No5 T CY', sans-serif;
    padding: 0;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    color: #333;
    font-size: var(--step-0);
}

li,
p {
    line-height: 1.5rem;
    margin: 0;
    font-size: var(--step-0);
}

a {
    font-weight: 500;
}

hr {
    border: 1px solid #ddd;
}

h1,
h2,
h3,
h4,
h5 {
    margin-bottom: 0;
}

#__next {
    width: 100%;
}
