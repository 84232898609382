@import 'src/styles/vars';

$heading-line-height: 1em;
.container {
    font-weight: normal;
}

.noWrap {
    white-space: nowrap;
}

.hideOverflow {
    overflow: hidden;
    text-overflow: ellipsis;
}

.title {
    margin: 0;
    display: inline;
    font-weight: $default-font-headings-weight;
    font-size: $default-font-gigantic;
    line-height: $heading-line-height * 1.4;
    //font-size: var(--step-1);
}

.h1 {
    margin-top: $default-margin;
    font-size: $default-font-h1;
    //font-size: var(--step-4);
    font-weight: $default-font-headings-weight;
    line-height: $heading-line-height * 1.3;
}

.h2 {
    margin-top: $default-margin;
    font-size: $default-font-h2;
    font-weight: $default-font-headings-weight;
    line-height: $heading-line-height * 1.3;
}

.h3 {
    margin-top: $default-margin;
    font-size: $default-font-h3;
    font-weight: $default-font-headings-weight;
    line-height: $heading-line-height * 1.2;
}

.h4 {
    margin-top: $default-margin;
    font-size: $default-font-h4;
    font-weight: $default-font-headings-weight;
    line-height: $heading-line-height * 1.1;
}

.h5 {
    margin-top: $default-margin;
    font-size: $default-font-h5;
    font-weight: $default-font-headings-weight;
    line-height: $heading-line-height;
}

.pointer {
    cursor: pointer;
}

.noMargin {
    margin: 0;
}

.text {
    font-size: $default-font-text;
    line-height: $heading-line-height;
}

.techText {
    font-family: monospace;
    font-size: $default-font-caption;
    white-space: nowrap;
}

.default {
}

.upper {
    align-self: flex-start;
}

.lower {
    align-self: flex-end;
}

.baseline {
    align-self: baseline;
}

/**
 * new design rules for typography
 */

.text {
    &.regular {
    }

    &.semibold {
    }
}

.caption {
    display: inline;
    font-style: normal;
    font-weight: 400;
    font-size: $default-font-caption;
    line-height: $heading-line-height;
    text-overflow: ellipsis;
    overflow: hidden;

    &.light {
        color: $theme-bird-font-common-color;
    }

    &.dark {
        color: $theme-bird-font-common-color;
    }

    &.warning {
        color: $default-warning-color;
    }

    &.disabled {
        color: $default-disabled-color;
    }

    &.white {
        color: white;
    }
}

.overline {
}

.bold {
    font-weight: 700;
}

.white {
    color: white;
}

.accent {
    color: $default-accent-color;
}

.disabled {
    color: $default-disabled-color;
}

.warning {
    color: $default-warning-color;
}
