.container {
    display: grid;
    grid-row-gap: 1rem;
}

.loadingContainer {
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    background: transparent !important;
    backdrop-filter: blur(5px);
}

.loadingText {
    color: white;
}