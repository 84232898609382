@import "src/styles/vars";

.container {
    display: grid;
    border-radius: $default-border-radius;
    box-sizing: border-box;
    overflow: hidden;
}

.content {
    padding: $default-margin;
}

.footer {
    padding-left: $default-margin;
    height: min-content;
    align-self: end;
}

$default-base-color: white;
.default {
    background: $default-base-color;
    box-shadow: 0 0 0 1px adjust-color($default-shadow-color, $alpha: -0.8),
    0 0 1em adjust-color($default-shadow-color, $alpha: -0.5);

    .footer {
        background: map-get($theme-colors, 'pallet-color-3');
        color: white;
    }
}

$warning-base-color: map-get($theme-colors, 'pallet-color-9');
.warning {
    color: white;
    background: $warning-base-color;
    box-shadow: 0 0 0 1px adjust-color($default-warning-color, $alpha: -0.8),
    0 0 1em adjust-color($default-warning-color, $alpha: -0.5);

    .footer {
        background: darken($warning-base-color, 10%);
    }
}

$success-base-color: map-get($theme-colors, 'pallet-color-6');
.success {
    color: white;
    background: $success-base-color;
    box-shadow: 0 0 0 1px darken($success-base-color, 20%),
    0 0 1em darken($success-base-color, 30%);

    .footer {
        background: darken($success-base-color, 10%);
    }
}

$critical-base-color: $default-critical-color;
.critical {
    color: white;
    background: $critical-base-color;
    box-shadow: 0 0 0 1px darken($critical-base-color, 20%),
    0 0 1em darken($critical-base-color, 30%);

    .footer {
        background: darken($critical-base-color, 10%);
    }
}